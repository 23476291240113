/* 
  Cek Akun Style
*/
.cek_akun__btn-check-group {
  display: flex;
}
.cek_akun__btn-check-submit {
  margin-left: 5px;
}

/* 
  Login Style
*/
.login__action-question {
  display: flex;
  justify-content: space-between;
}

/* 
  Register Style
*/

.register__btn-register-group {
  display: flex;
}

.register__btn-register-next {
  margin-left: 5px;
}

/* BRS help_button */
.BRS_help_button {
  padding: 10;
  color: #222;
  cursor: pointer;
}

.BRS_help_button:hover {
  color: #4c56c0;
}

/* 
  Responsive Devices
*/
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .cek_akun__btn-check-group {
    flex-direction: column-reverse;
  }
  .cek_akun__btn-check-submit {
    margin: 5px 0 5px 0;
  }
  .login__action-question {
    flex-direction: column;
  }
  .register__btn-register-group {
    display: flex;
    flex-direction: column;
  }
  .register__btn-register-next {
    margin: 5px 0 5px 0;
  }
}

.content {
  margin-top: 1rem !important;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .content {
    margin-top: 1rem !important;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .content {
    margin-top: 1rem !important;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .content {
    margin-top: 1rem !important;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
